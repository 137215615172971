import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RoleType } from '../enums/user/role-types.enum';
import { IUser } from '../models/user/user.model';
import { IOrgCustomDomainsVerifiedFE, IOrganizationCustomDomain } from '../models/organization/custom-domain.model';
import { BehaviorSubject } from 'rxjs';
import { Operation } from '../enums/utils/operations.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public wantedUrl: string | null = null;
  public waitingForDeactivationCheck$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  /**
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   * !!!!!!!!!!!!!!!  READ THIS  !!!!!!!!!!!!!
   * 
   * for every new method decide if and how it should use orgCustomDomains
   * 
   * !!!!!!!!!!!!!!!  READ THIS  !!!!!!!!!!!!!
   * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   */


  public goToAttendanceDocumentTemplateEditor({
    operation,
    orgUuid,
    templateUuid,
    newTab = false
  }: {
    operation: Operation,
    orgUuid: string,
    templateUuid?: string,
    newTab?: boolean
  }) {
    const path = ['attendance-documents', 'template-editor', operation, orgUuid];
    if (templateUuid) path.push(templateUuid);

    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }

  public goToCertificateTemplateEditor({
    operation,
    orgUuid,
    templateUuid,
    newTab = false
  }: {
    operation: Operation,
    orgUuid: string,
    templateUuid?: string,
    newTab?: boolean
  }) {
    const path = ['certificates', 'template-editor', operation, orgUuid];
    if (templateUuid) path.push(templateUuid);

    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }

  public goToAdminShopItemTemplate({
    shopItemUuid,
    newTab = false
  }: {
    shopItemUuid: string,
    newTab?: boolean
  }) {
    const path = ['admin', 'org-admin', 'events', 'item-templates', shopItemUuid ];

    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }
  
  public goToTicket(
    {
      ticketUuid,
      newTab = false,
      orgCustomDomains
    }: {
      ticketUuid: string,
      newTab?: boolean,
      orgCustomDomains: IOrgCustomDomainsVerifiedFE
    }) {
    const path = ['ticket', ticketUuid];
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate(path, orgCustomDomains.customerDomain);
    }
  }

  public goToUserAccountSettings() {
    const path = ['admin', 'user', 'account-settings'];

    return this.router.navigate(path);
  }

  public goToArrangeMeeting() {
    window.open('https://calendly.com/zavadil-petr/ukazka-lektory-app', '_blank')?.focus();
  }

  public goToShopItemCheckin({
    shopItemUuid,
    newTab = false
  }: {
    shopItemUuid: string,
    newTab?: boolean
  }) {
    const path = ['item', shopItemUuid, 'checkin'];

    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }

  public goToEventCheckin({
    eventUrlId,
    newTab = false
  }: {
    eventUrlId: string,
    newTab?: boolean
  }) {
    const path = [eventUrlId, 'checkin'];

    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }

  public goToOffer({
    offerUuid,
    orgCustomDomains,
    newTab = false,
    returnUrl,
  }: {
    offerUuid: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE
    newTab?: boolean,
    returnUrl?: boolean,
  }) {
    const path = ['offer', offerUuid];
    const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
    const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;

    if (returnUrl) {
      return url; // just url
    }

    if (newTab) {
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate(path, orgCustomDomains.customerDomain);
    }
  }

  public goToOrderInvoice({
    orderUuid,
    orgCustomDomains,
    newTab = false
  }: {
    orderUuid: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
    newTab: boolean,
  }) {
    const path = this.getPathToOrderInvoice(orderUuid);
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree([path]) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate([path], orgCustomDomains.customerDomain);
    }
  }
  public getPathToOrderInvoice(orderUuid: string) {
    return `customer-orders/${orderUuid}/invoice`;
  }

  public goToCustomerOrderPaymentsReceipts({
    orderUuid,
    orgCustomDomains,
    newTab = false
  }: {
    orderUuid: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
    newTab: boolean
  }) {
    const path = `customer-orders/${orderUuid}/payments-receipts`;
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree([path]) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate([path], orgCustomDomains.customerDomain);
    }
  }

    // const path = ['ticket', ticketUuid];
  // if (newTab) {
  //   const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
  //   const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
  //   window.open(url, '_blank');
  //   return new Promise((resolve) => resolve(true));
  // } else {
  //   return this.navigate(path, orgCustomDomains.customerDomain);
  // }

  public goToPaymentTaxReceipt({
    transferUuid,
    orgCustomDomains,
    newTab = false
  }: {
    transferUuid: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
    newTab: boolean
  }) {
    const path = `customer-orders/payments/${transferUuid}/tax-receipt`;
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree([path]) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate([path], orgCustomDomains.customerDomain);
    }
  }

  public goToCustomerPaymentReceipt({
    transferUuid,
    orgCustomDomains,
    newTab = false
  }: {
    transferUuid: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
    newTab: boolean
  }) {
    const path = `customer-orders/payments/${transferUuid}/receipt`;
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree([path]) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate([path], orgCustomDomains.customerDomain);
    }
  }

  public goToCustomerPayment({
    transferUuid,
    orgCustomDomains,
    newTab = false
  }: {
    transferUuid: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
    newTab: boolean
  }) {
    const path = `customer-orders/payments/${transferUuid}/receipt`;
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree([path]) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate([path], orgCustomDomains.customerDomain);
    }
  }

  public goToCustomerSingleOrder({
    orderUuid,
    newTab = false,
    orgCustomDomains
  }: {
    orderUuid: string,
    newTab?: boolean,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE
  }) {
    const path = `customer-orders/${orderUuid}`;
    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree([path]) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate([path], orgCustomDomains.customerDomain);
    }
  }

  public goToCustomerOrdersInOrganization({
    orgId,
    orgCustomDomains,
    magicCodeUuid,
    newTab = false,
    returnUrl
  }: {
    orgId: number,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
    magicCodeUuid: string,
    newTab?: boolean,
    returnUrl?: boolean
  }) {
    const path = ['customer-orders', `${orgId}`, magicCodeUuid];
    const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
    const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;

    if (returnUrl) {
      return new Promise((resolve) => resolve(url)); // just url
    }

    if (newTab) {
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate(path, orgCustomDomains.customerDomain);
    }
  }

  public goToCustomerOrdersInOrganizationFromPaymentGate({
    orgId,
    PG_CODE,
    orgCustomDomains
  }: {
    orgId: number,
    PG_CODE: string,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE
  }) {
    return this.navigate([`customer-orders/from-paymentgate/${orgId}/${PG_CODE}`], orgCustomDomains.customerDomain);
  }

  public goToAdminShopItem({
    eventUuid,
    shopItemUuid,
    newTab = false
  }: {
    eventUuid: string,
    shopItemUuid: string,
    newTab?: boolean
  }) {
    const path = ['admin', 'org-admin', 'events', eventUuid, shopItemUuid ];

    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }
  


  // todo neni idealni... pouzivano pri změne selectedOrgId aby se vlastně triggernulo canActivate aby se zjistilo
  // jestli pri zmene organizace user muze zustat na dane strance ... protoze se muze stat, ze v jine org bude mit
  // jine role, tak aby se mu nezobrazila admin stranka v jine org
  // ....... to je sice fajn, ale timto se vlastne reInitne i ta komponenta, ktera pat opět inicializuje state v store
  // store je ale udelanej tak, aby automaticky re-initoval svuj stav po zmene selectedOrgId
  // takze vysledek je takovej, ze ten init stavu probehne 2x
  // EDIT: ted probehne pouze 1x, protoze jsem odstranil subscriptions na selectedOrgId ve storech
  public async reloadRoute() {
    const path = this.router.url;
    await this.router.navigate([''], { skipLocationChange: true });
    await this.router.navigate([path]);
  }

  public goToShopItemPresentation({
    shopItemUuid,
    newTab = false,
    getUrl = false,
    orgCustomDomains,
  }: {
    shopItemUuid: string,
    newTab?: boolean,
    getUrl?: boolean,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
  }) {
    const path = ['item', shopItemUuid];

    if (getUrl) {
      return this.getUrl(path, orgCustomDomains.customerDomain);
    }

    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate(path, orgCustomDomains.customerDomain);
    }
  }

  public goToEventPresentation({
    eventUrlId,
    newTab = false,
    getUrl = false,
    orgCustomDomains,
  }: {
    eventUrlId: string,
    newTab?: boolean,
    getUrl?: boolean,
    orgCustomDomains: IOrgCustomDomainsVerifiedFE,
  }) {
    const path = [eventUrlId];

    if (getUrl) {
      return this.getUrl(path, orgCustomDomains.customerDomain);
    }

    if (newTab) {
      const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
      const url = this.getCustomerBaseUrl(orgCustomDomains) + urlPath;
      window.open(url, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.navigate(path, orgCustomDomains.customerDomain);
    }
  }

  public goToPrevWantedUrl() {
    this.router.navigate([this.wantedUrl]);
    this.wantedUrl = null;
  }

  public goToAdminEvent({
    eventUuid,
    newTab = false,
  }: {
    eventUuid: string,
    newTab?: boolean
  }) {
    const path = ['admin', 'org-admin', 'events', eventUuid];
    if (newTab) {
      const urlPath = this.router.serializeUrl(
        this.router.createUrlTree(path)
      );
      window.open(urlPath, '_blank');
      return new Promise((resolve) => resolve(true));
    } else {
      return this.router.navigate(path);
    }
  }

  public goToAdminEvents() {
    return this.router.navigate([`admin/org-admin/events`]);
  }

  public goToBase() {
    return this.router.navigate(['']);
  }

  public goToSignIn() {
    return this.router.navigate(['auth/sign-in']);
  }

  public goToOrgAdminDashboard() {
    // return this.router.navigate(['admin/org-admin/dashboard']);
    return this.router.navigate(['admin/org-admin/events']);
  }

  public goToLecturerDashboard() {
    return this.router.navigate(['admin/lecturer/sessions']);
  }

  public goToCustomerDashboard() {
    return this.router.navigate(['admin/customer/my-orders']);
  }

  public goToCoordinatorDashboard() {
    return this.router.navigate(['admin/org-admin/events']);
  }

  public goToUsersDashboard(user: IUser, orgId?: number, retries: number = 0): Promise<boolean> {
    
    const userRoleTypes = orgId ? user.roles.filter(x => x.organizationId === orgId).map(x => x.type) : user.roles.map(x => x.type);

    if (userRoleTypes.includes(RoleType.ORG_ADMIN) || userRoleTypes.includes(RoleType.ORG_OWNER)) {
      return this.goToOrgAdminDashboard();
    } else if (userRoleTypes.includes(RoleType.LECTURER)) {
      return this.goToLecturerDashboard();
    } else if (userRoleTypes.includes(RoleType.COORDINATOR)) {
      return this.goToCoordinatorDashboard();
    } else if (userRoleTypes.includes(RoleType.CUSTOMER)) {
      return this.goToCustomerDashboard();
    } else {
      // try to find some orgId
      const anyOrgId = user.roles[Math.floor(Math.random() * user.roles.length)].organizationId;
      // randomly not found, try again
      if (!anyOrgId && retries < 10) {
        return this.goToUsersDashboard(user, undefined, ++retries);
      }
      // orgId randomly found, and try navigate again
      if (anyOrgId && retries < 10) return this.goToUsersDashboard(user, anyOrgId, ++retries);
      else {
        console.error('NOT IMPLEMENTED! userrole dashboard route kqwelk12l12');
        return this.goToBase();
      }
    }
  }

  private getCustomerBaseUrl(orgCustomDomains: IOrgCustomDomainsVerifiedFE) {
    return orgCustomDomains?.customerDomain ? 'https://' + orgCustomDomains.customerDomain : environment.baseUrl;
  }
  private getAdminBaseUrl(orgCustomDomains: IOrgCustomDomainsVerifiedFE) {
    // return orgCustomDomains?.adminDomain ? 'https://' + orgCustomDomains.adminDomain : environment.baseUrl;
    console.error('NOT IMPLEMENTED! getAdminBaseUrl');
  }

  private navigate(path: string[], customDomain: string | null) {
    const currentHost = window.location.host;
    if (customDomain) {
      if (customDomain === currentHost) {
        return this.router.navigate(path);
      } else {
        const urlPath = this.router.serializeUrl( this.router.createUrlTree(path) );
        const url = 'https://' + customDomain + urlPath + window.location.search;
        return new Promise((resolve) => {
          window.location.href = url;
          resolve(true);
        });
      }
    } else {
      return this.router.navigate(path);
    }
  }

  private getUrl(path: string[], customDomain: string | null) {
    const currentHost = window.location.host;
    if (customDomain) {
      if (customDomain === currentHost) {
        return currentHost + this.router.serializeUrl( this.router.createUrlTree(path) );
      } else {
        return 'https://' + customDomain + this.router.serializeUrl( this.router.createUrlTree(path) );
      }
    } else {
      return environment.baseUrl + this.router.serializeUrl( this.router.createUrlTree(path) );
    }
  }
}
